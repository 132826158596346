.componentBox {
  border-bottom: solid 1px var(--gray-color);
  padding: 30px 20px 30px 0px;
  justify-content: center;
  height: 5.5rem !important;
}

.containerDiv {
  width: 100%;
  margin-bottom: 1rem;
}

.containerDivMessage {
  flex-direction: column;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-right:  0.3rem;
  margin-top: 0.3rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray-color);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 40%;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 5px;
  background-color: var(--light-color);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--dark-color);
}

input:focus+.slider {
  box-shadow: 0 0 1px var(--dark-color);
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


.sliderLabel{
  font-size: large !important;
  font-weight: 600 !important;
  margin: 0px 5px 5px 0px !important;
}

.slider.round:before {
  border-radius: 50%;
}

.inputsLabels {
  margin-left: 7px;
  border-color: var(--dark-light-color) !important;
}

.focus {
  display: block;
  outline: none;
  height: 2.5rem;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: var(--light-color);
  background-clip: padding-box;
  border: 1px solid #6969697e !important; 
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.focus:focus{
  box-shadow: 0px 0px 5px 2px var(--dark-color);
-webkit-box-shadow: 0px 0px 5px 2px var(--dark-color);
-moz-box-shadow: 0px 0px 5px 2px var(--dark-color);
}