.headerDash {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    grid-gap: 1rem;
    width: 100%;
}

.contents {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    grid-gap: 1rem;
    width: 100%;
    height: 100%;
}

.lineBar {
    display: block;

}

.contents2 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    grid-gap: 0.5rem;
    width: 100%;
    height: 100%;
}

.p-button {
    background: var(--dark-color) !important;
    border: var(--dark-color) !important;
}

.red {
    background-color: var(--red-color) !important;
}

.info {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 0.5rem;
    align-items: end;
    height: 100%;
    width: 100%;
    justify-content: center;
}

.headerDash p {
    display: flex;
    justify-content: flex-start;
}

.bodyDash {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30rem;
    margin-top: 4rem;
    width: 100%;
    height: 40rem;
}

.containerBoxChart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 14rem;
}

button {
    height: 2.5rem;
    color: var(--light-color) !important;
}


.speeddial-tooltip-demo .p-speeddial-direction-up.speeddial-left {
    left: 0;
    bottom: 0;
}

.speeddial-tooltip-demo .p-speeddial-direction-up.speeddial-right {
    right: 0;
    bottom: 0;
}

.p-button.p-button-danger:enabled:focus {
    box-shadow: 0 0 0 2px var(--light-color), 0 0 0 4px rgb(31, 31, 31), 0 1px 2px 0 var(--dark-color) !important;
}



/* version Tablet 689px hasta 991px */
@media (min-width: 689px) {
    .headerDash {
        display: grid;
        grid-template-columns: 50% 49%;
        grid-template-rows: 100%;
        grid-gap: 1rem;
        width: 90%;
        margin: auto;
    }

    .contents {
        height: 100%;
    }

    .contents2 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 50% 50%;
        grid-gap: 1rem;
    }

    .info {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 0.5rem;
        align-items: end;
        height: 100%;
        width: 100%;
        justify-content: center;
        grid-template-rows: 23.1% 23.1% 23.1% 23.1%;
    }

    .bodyDash {
        margin-top: 3rem;
        margin: auto;
        width: 90%;
    }
}



/* version Desktop 992px hasta 1311px */
@media (min-width: 992px) {
    .contents2 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 50% 50%;
        grid-gap: 1rem;
    }

    .info {
        grid-template-rows: 23.4% 23.4% 23.4% 24%;
        grid-auto-rows: minmax(100px, auto);
    }

    .bodyDash {
        margin-top: 2rem;
    }
}



/* version Full-Desktop 992px hasta 1311px */
@media (min-width: 1312px) {

    .contents2 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 35% 65%;
        grid-gap: 1rem;
    }

    .info {
        display: grid;
        grid-template-columns: 49% 49%;
        grid-template-rows: 40% 40%;
        grid-gap: 1rem;
        align-items: start;
        justify-items: center;
    }

    .containerBoxChart {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 20rem;
    }
}