.containerInfo {
    display: flex;
    background-color: var(--light-color);
    height: 6rem;
    width: 45%;
    min-height: fit-content !important;
    margin: 0px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 4px 10px 0px var(--light-shadow-color);
    -moz-box-shadow: 0px 4px 10px 0px var(--light-shadow-color);
    box-shadow: 0px 4px 10px 0px var(--light-shadow-color);
    padding-left: 0.6rem;
}

.headerInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
}

.bodyInfo {
    display: flex;
    flex-direction: column;
    /*align-items: flex-start;*/
    justify-content: center;
    font-size: 0.9rem;
    padding-top: 1rem;
    padding-right: 1rem;
    overflow: hidden;
}

.bodyInfo h5 {
    font-size: 1rem;
    word-wrap: break-word;
}

.bodyInfo p {
    color: var(--secondary-color);
}


@media (min-width: 689px) {
    .containerInfo {
        padding-left: 1.6rem;
        margin: 0;
    }

    .bodyInfo {
        font-size: 1.1rem;
        padding-left: 0.5rem;
    }
}


@media (min-width: 1312px) {

    .containerInfo {
        justify-items: center;
        padding-left: 1rem;
    }

    .bodyInfo {
        font-size: 0.9rem;
        padding-left: 0.5rem;
    }
}