.bodyPaper{
    background-color: var(--light-color); 
    width:80%;
    height:100%;
    margin: 1rem;
    padding: 2rem;
    -webkit-box-shadow: 0px 0px 15px -5px var(--dark-shadow-color);
    -moz-box-shadow: 0px 0px 15px -5px var(--dark-shadow-color);
    box-shadow: 0px 0px 15px -5px var(--dark-shadow-color);
}

.containerPaper{
    padding: 1px;
}

.container-toggle {
    width: 100%;
    display: flex;
    align-items: center;
}

.buttonsApps {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.slider-label {
    font-size: 1.2rem;
    font-weight: 600 !important;
    margin: 0 5px 5px 0;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    align-items: center;
    margin-bottom: 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray-color);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 40%;
}

.slider:before{
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 5px;
    background-color: var(--light-color);
    -webkit-transition: .4s;
    transition: .4s; 
}

.slider.round:before {
    border-radius: 50%;
}

input:checked+.slider {
    background-color: var(--dark-color);
}

input:focus+.slider {
    box-shadow: 0 0 1px var(--dark-color);
}
  
input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
  
.inputs-labels {
    margin-left: 7px;
    border-color: var(--dark-light-color);
}