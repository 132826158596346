.containerBox{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--light-color);
    margin: 0;
    border-radius: 10px;
    -webkit-box-shadow: 0px 4px 10px 0px var(--light-shadow-color);
    -moz-box-shadow: 0px 4px 10px 0px var(--light-shadow-color);
    box-shadow: 0px 4px 10px 0px var(--light-shadow-color);
}

.headerBox{
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 15px 20px 0px 20px;
    margin-bottom: 1rem;
}

.chartjs-render-monitor{
    object-fit: contain;
}

h5{
    margin: 0;
}

.bodyBox{
    padding: 0 20px 50px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 689px) {

    .containerBox{
        min-height: 72%;
    }
}

@media (min-width: 992px) {

    .containerBox{
        min-height: 90.5%;
    }
}

@media (min-width: 1312px) {
}