.spaceCardApps {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.headerManager {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 2rem 0;
}

.picklist-demo .product-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
}

.picklist-demo .product-item img {
    width: 75px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 1rem;
}

.picklist-demo .product-item .product-list-detail {
    flex: 1 1 0;
}

.picklist-demo .product-item .product-list-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.picklist-demo .product-item .product-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
}

.picklist-demo .product-item .product-category {
    vertical-align: middle;
    line-height: 1;
}

.linkButton {
    width: auto !important;
}

.button, .buttonSave {
    background-color: #000000 !important;
}

.dropdownCategorys{
    font-weight: bold !important;
}

.buttonsManager {
    display: flex;
    flex-direction: row;
    width: 240px;
    justify-content: space-between;
}

@media screen and (max-width: 689px) {
    .picklist-demo .product-item {
        flex-wrap: wrap;
    }

    .picklist-demo .product-item .image-container {
        width: 100%;
        text-align: center;
    }

    .picklist-demo .product-item img {
        margin: 0 0 1rem 0;
        width: 100px;
    }

    .linkButton {
        width: 100% !important;
    }

    .button {
        width: 100%;
    }

    .headerManager {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .dropdownCategorys{
        width: 100%;
    }

    .buttonsManager {
        flex-direction: column;
        width: 100%;
        margin-bottom: 1rem;
    }
}