.mapHistory{
    max-width: 1100px !important;
    height: 80vh !important;
    margin: 2rem auto 0 auto;
}

.popup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
