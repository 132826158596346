.bodyFooter{
    display: inline-flex;
    background-color: rgb(12, 12, 12);
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7rem;
    padding: 0.5rem;
}

.icons{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.icons i {
    color: rgb(255, 255, 255);
    padding: 0;
    margin: 0;
    font-size: 2.2rem; 
}

.copyright{
    width: 100%;
    height: 1rem;
    background-color: var(--dark-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem;
}

.copyright p{
    color: var(--light-color);
    margin: 0;
}

@media (min-width: 689px) {

    .bodyFooter{
        height: 15rem;
    }

    .icons{
        justify-content: space-around;
    }

    .icons i {
        font-size: 3.5rem; 
    }
}

/* version Desktop 992px hasta 1311px */
@media (min-width: 992px) {}

/* version Full-Desktop 992px hasta 1311px */
@media (min-width: 1312px) {

    .bodyFooter{
        height: 17rem;
    }

    .icons i {
        font-size: 4rem; 
    }
}
