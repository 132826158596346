.end-footer-body{
    display:flex;
    justify-content: flex-end;
    padding: 1rem 0rem;
}

.sectionBody{
    height: 100% !important;
}

.containerHeight{
    padding: 2rem 2rem;
    height: 100% !important;
    background-color: var(--light-color);
    min-height: 100vh;
}

.container-toggle{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
}

.containerIndex{
    min-height: 100vh;
    margin-left: 0px !important;
}

#resolution{
    width: 17rem;
}

.divMaps{
    max-width: 1100px !important;
    height: 100% !important;
    margin: 3rem 0;
}

.leaflet-container{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

.row{
    width: 100%;
    margin: 0 !important;
}

.content-wrapper{
    min-height: 100vh !important;
}

.contentIndex{
    width: 100%;
}

.size-icon{
    font-size: x-large !important;
}

.zoom:hover{
    transform: scale(1.2);
    cursor: pointer;
}

.trash{
    margin-left: 0.8rem;
}

.carrousel img{
    height: 35rem;
}

.marginCharts{
    border: 1px solid var(--gray-color);
    padding: 1.5rem;
    margin: 1rem;
}

.flex{
    display: flex;
    justify-content: space-between;
}


.p-toast-bottom-right{
    z-index: 9999;
}

.p-chart {
    width: 100% !important;
    height: 100%;
}

.p-picklist-source-controls{
    display: none !important;
}

.p-picklist-target-controls{
    display: none !important;
}

.p-button{
    background: black !important;
    border: 1px solid black !important;
}

.p-picklist-transfer-buttons :nth-child(4n) {
    display: none !important;
}

.p-picklist-transfer-buttons :nth-child(2n) {
    display: none !important;
}

.p-listbox-empty-message{
    display: flex !important;
    justify-content: center !important;
}

.navbar-light .navbar-nav .nav-link{
    color: var(--dark-shadow-color) !important;
}

input.error {
    box-shadow: 0 0 5px red !important;
}

select.error {
    box-shadow: 0 0 5px red !important;
}
select.error:focus {
    border: 1px solid red !important;
}

/* version Tablet 689px hasta 991px */
@media (min-width: 689px) {
    .containerHeight{
        padding: 2rem 3rem;
    }
}

@media (max-width: 689px) {
    .p-toast{
        width: 18rem !important;
    }
}